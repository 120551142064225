import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarouselConfig, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { lastValueFrom } from 'rxjs';

import { DataService } from '../../services/data/data.service';
import { FileService } from '../../services/file/file.service';
import { AppRoute } from '../../shared/constants/app-route.contants';
import { MediaStatus, MediaType } from '../../shared/enums/media-status.enum';
import { SculptureMediaURLV3 } from '../../shared/interfaces/response-v3.interface';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-sculpture-v3',
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    NgbCarouselModule
  ],
  templateUrl: './sculpture-v3.component.html',
  styleUrl: './sculpture-v3.component.scss'
})
export class SculptureV3Component {

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private fileService: FileService,
    private title: Title,
    private router: Router,
    private ngbCarouselConfig: NgbCarouselConfig

  ) {
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.currentId = params.get('id');
      this.mediaStatus = params.get('status') as MediaStatus;
    });
    ngbCarouselConfig.interval = 30000;
    this.title.setTitle("Download - Sculpture Bangkok");
  }

  isLoading: boolean = true;
  currentId: string = '';
  mediaStatus: MediaStatus = MediaStatus.UNDEFINED;
  mediaUrls: SculptureMediaURLV3[] = [];
  public MediaType = MediaType;

  async ngOnInit() {
    try {
      this.mediaStatus = this.mediaStatus ? this.mediaStatus : MediaStatus.READY;
      await this.loadMediaUrls();
    }
    catch (err) {
      console.error(err);
    }
  }

  async loadMediaUrls() {
    this.mediaUrls = await lastValueFrom(this.dataService.getMediaUrlById(this.currentId));
    if(this.mediaUrls.length === 0) {
      this.router.navigate([AppRoute.ERROR_NOT_FOUND]);
    }
  }

  loadDone() {
    this.isLoading = false;
  }

  async downloadMedia(media: SculptureMediaURLV3) {
    
    let timerInterval;
    Swal.fire({
      icon: 'info',
      title: "Downloading",
      html: "Please wait a moment before the download starts.",
      timer: 5000,
      timerProgressBar: true,
      willClose: () => {
        clearInterval(timerInterval);
      }
    })

    const fileName = `${media.media_type}_${this.currentId}.${this.fileService.getFileExtension(media.media_type)}`;
    saveAs(media.url, fileName);
  }

}
