import { Routes } from '@angular/router';

import { ErrorNotFoundComponent } from './pages/error-not-found/error-not-found.component';
import { SculptureV2Component } from './pages/sculpture-v2/sculpture-v2.component';
import { SculptureV3Component } from './pages/sculpture-v3/sculpture-v3.component';
import { SnapComponent } from './pages/snap/snap.component';
import { AppRoute } from './shared/constants/app-route.contants';

export const routes: Routes = [
    { path: '', redirectTo: AppRoute.ERROR_NOT_FOUND, pathMatch: 'full' },
    { path: AppRoute.SCULPTURE_V2, component: SculptureV2Component },
    { path: AppRoute.SCULPTURE_V3, component: SculptureV3Component },
    { path: AppRoute.SNAP, component: SnapComponent },
    { path: AppRoute.ERROR_NOT_FOUND, component: ErrorNotFoundComponent },
    { path: '**', component: ErrorNotFoundComponent }
];
