import { Component } from '@angular/core';

@Component({
  selector: 'app-sculpture-v2',
  standalone: true,
  imports: [],
  templateUrl: './sculpture-v2.component.html',
  styleUrl: './sculpture-v2.component.scss'
})
export class SculptureV2Component {

}
