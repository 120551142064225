import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MediaType } from '../../shared/enums/media-status.enum';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private httpClient: HttpClient
  ) { }

  downloadFile(url: string, fileName: string): Observable<Blob> {
    return this.httpClient.get(`${url}`, {responseType: 'blob' })
}

  getFileExtension(mediaType: MediaType) {
    switch (mediaType) {
      case MediaType.IMAGE:
        return 'png';
      case MediaType.VIDEO:
        return 'mp4';
      case MediaType.ANIMATION:
        return 'gif';
      default:
        return '';
    }
  }
}
