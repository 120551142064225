<div class="content-wrapper">
    <div class="container d-flex flex-column justify-content-center align-items-center">
        <div class="row text-center">
            <div class="col-12 p-5 pb-3 pt-3">
                <img class="img-fluid" src="assets/images/sculpture-logo.png" alt="" />
                <small *ngIf="mediaUrls.length > 0"><strong>CHOOSE YOUR FILES BELOW</strong></small>
            </div>
        </div>
        <div class="row text-center" style="height: 60vh" *ngIf="mediaUrls.length > 0">
            <div class="col-12">
                @if (mediaUrls) {
                <ngb-carousel>
                    <ng-template ngbSlide *ngFor="let media of mediaUrls">
                        <div *ngIf="media.media_type === MediaType.IMAGE">
                            <div class="d-flex justify-content-between align-items-center" style="height: 60vh"
                                *ngIf="isLoading">
                                <img src="assets/images/loading.gif" alt="" />
                            </div>
                            <img class="" [src]="media.url" [hidden]="isLoading" (load)="loadDone()"
                                style="height: 60vh" alt="" />
                        </div>
                        <div *ngIf="media.media_type === MediaType.VIDEO">
                            <div class="d-flex justify-content-between align-items-center" style="height: 60vh">
                                <video width="320" height="240" autoplay muted>
                                    <source [src]="media.url" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                        <div *ngIf="media.media_type === MediaType.ANIMATION">
                            <div class="d-flex justify-content-between align-items-center" style="height: 60vh">
                                <div class="d-flex justify-content-between align-items-center">
                                    <img class="" [src]="media.url" [hidden]="isLoading" (load)="loadDone()"
                                        style="width: 100%;" alt="" />
                                </div>
                            </div>
                        </div>
                    </ng-template>+
                </ngb-carousel>
                }
            </div>
        </div>
        <div class="row mt-5 mr-3 ml-3 d-flex justify-content-around align-items-center" style="width: 100%" *ngIf="mediaUrls.length > 0">
            @if (mediaUrls) {
            <div class="col-4 text-center" *ngFor="let media of mediaUrls">
                <div *ngIf="media.media_type === MediaType.IMAGE">
                    <a (click)="downloadMedia(media)"><img class="bw-icon" src="assets/images/photo.gif" alt="download image" /></a>
                    <p><strong>PHOTO</strong></p>
                </div>
                <div *ngIf="media.media_type === MediaType.VIDEO">
                    <a (click)="downloadMedia(media)"><img class="bw-icon" src="assets/images/video.gif" alt="download video" /></a>
                    <p><strong>VIDEO</strong></p>
                </div>
                <div *ngIf="media.media_type === MediaType.ANIMATION">
                    <a (click)="downloadMedia(media)"><img class="bw-icon" src="assets/images/video.gif" alt="download gif" /></a>
                    <p><strong>GIF</strong></p>
                </div>
            </div>
            }
        </div>
    </div>
</div>
<!-- <div class="container content-wrapper d-flex flex-column justify-content-center align-items-center">
    
</div> -->

<!-- <img src="https://api.snapphotoservice.com/view-final-photo.php?id=9383d448-c719-4ce1-a967-ef905ab6306f"
                    alt="sculpture logo" class="img-fluid preview-image"> -->

<!-- <img src="assets/images/sculpture-logo.png" alt="sculpture logo" class="img-fluid"> -->